import { Formik } from "formik";
import * as yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

interface PropsType {
  task: {
    title: string;
  };
  onSubmit: (
    input: { title: string },
    config: { setSubmitting: (a: boolean) => void }
  ) => void;
}

const schema = yup.object().shape({
  title: yup.string().required("Title required"),
});

function EditForm({ task, onSubmit }: PropsType) {
  return (
    <Formik
      initialValues={{
        title: task.title,
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                type="title"
                placeholder="Run 100m backwards"
                isValid={touched.title && !errors.title}
                isInvalid={touched.title && !!errors.title}
              />
              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              className="mt-2"
            >
              Submit
            </Button>
            <div style={{ paddingTop: "1rem" }}>
              {!!status && <Alert variant="danger">{status}</Alert>}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default EditForm;
