/**
 * @generated SignedSource<<651a56d3014f6aea4e020be305eed829>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectMonth = "april" | "august" | "december" | "february" | "january" | "july" | "june" | "march" | "may" | "november" | "october" | "september" | "%future added value";
export type ProjectUpdateInput = {
  clientMutationId?: string | null | undefined;
  project: ProjectUpdateInputType;
};
export type ProjectUpdateInputType = {
  completed?: boolean | null | undefined;
  description?: string | null | undefined;
  id: string;
  index?: number | null | undefined;
  month?: ProjectMonth | null | undefined;
  name?: string | null | undefined;
};
export type DescriptionForm_ProjectMutation$variables = {
  input: ProjectUpdateInput;
};
export type DescriptionForm_ProjectMutation$data = {
  readonly updateProject: {
    readonly project: {
      readonly " $fragmentSpreads": FragmentRefs<"ProjectsPage_project">;
    };
  };
};
export type DescriptionForm_ProjectMutation = {
  response: DescriptionForm_ProjectMutation$data;
  variables: DescriptionForm_ProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DescriptionForm_ProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectUpdatePayload",
        "kind": "LinkedField",
        "name": "updateProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectsPage_project"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DescriptionForm_ProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectUpdatePayload",
        "kind": "LinkedField",
        "name": "updateProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "month",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "assignees",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "647942dc8ec7b93dbfac1cbcf72945dc",
    "id": null,
    "metadata": {},
    "name": "DescriptionForm_ProjectMutation",
    "operationKind": "mutation",
    "text": "mutation DescriptionForm_ProjectMutation(\n  $input: ProjectUpdateInput!\n) {\n  updateProject(input: $input) {\n    project {\n      ...ProjectsPage_project\n      id\n    }\n  }\n}\n\nfragment Assignment_Assignees on UserConnection {\n  edges {\n    node {\n      id\n      name\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  id\n  name\n  email\n  imageUrl\n}\n\nfragment Avatars_users on UserConnection {\n  ...Assignment_Assignees\n  edges {\n    node {\n      id\n      ...Avatar_user\n    }\n  }\n}\n\nfragment ProjectsPage_project on Project {\n  id\n  order\n  code\n  name\n  completed\n  description\n  month\n  assignees {\n    ...Avatars_users\n    ...Assignment_Assignees\n  }\n  comments {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "0cb117d588f72de55dd5ab8a58ce8352";

export default node;
