import { useState } from "react";
import { Modal } from "react-bootstrap";
import EditForm from "./EditForm";

type SetSubmittingType = (a: boolean) => void;
interface TaskableInputs {
  title: string;
}
interface PropsType {
  task: TaskableInputs;
  onSubmit: (
    input: TaskableInputs,
    config: {
      onCompleted?: () => void;
      setSubmitting?: SetSubmittingType | undefined;
    },
  ) => void;
}

const modalBodyStyle = {
  minHeight: "22rem",
};

function CreateTaskModal({
  task,
  onSubmit,
  show,
  toggleShow,
}: PropsType & {
  show: boolean;
  toggleShow: () => void;
}) {
  const handleSubmit = (
    input: TaskableInputs,
    config: { setSubmitting: SetSubmittingType },
  ) => {
    onSubmit(input, { onCompleted: toggleShow, ...config });
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={toggleShow}
      >
        <Modal.Header closeButton>Edit</Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <EditForm task={task} onSubmit={handleSubmit} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export function useEditModal(props: PropsType) {
  const [show, updateShow] = useState(false);
  const toggleShow = () => {
    updateShow(!show);
  };

  const Modal = () => <CreateTaskModal {...{ show, toggleShow, ...props }} />;

  return { toggleShow, Modal };
}
